import uniqBy from 'lodash/uniqBy';
import {
  CONCAT_USER_NOTIFICATIONS,
  GET_USER_NOTIFICATIONS,
  READ_ALL_NOTIFICATIONS,
  RESET_USER_NOTIFICATIONS,
  READ_USER_NOTIFICATION,
  SET_USER_NOTIFICATION,
  RESET_USER_NOTIFICATION,
} from '../actions/Types';
import size from 'lodash/size';

const initialState = {
  userNotifications: [],
  allReadNotifications: [],
  allNewNotifications: [],
  totalUnreadNotifications: 0,
  notificationId: null,
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_NOTIFICATIONS:
      return {
        ...state,
        allNewNotifications: action.payload.un_read_notifications,
        allReadNotifications: action.payload.all_read_notifications,
        totalUnreadNotifications: size(action.payload.un_read_notifications),
      };
    case READ_ALL_NOTIFICATIONS:
      return { ...state, allNewNotifications: [], totalUnreadNotifications: 0, allReadNotifications: action.payload.all_read_notifications };
    case CONCAT_USER_NOTIFICATIONS:
      const newNotifications = uniqBy([action.payload, ...state.allNewNotifications], 'id');
      return { ...state, allNewNotifications: newNotifications, totalUnreadNotifications: size(newNotifications) };
    case RESET_USER_NOTIFICATIONS:
      return { ...state, totalUnreadNotifications: 0 };
    case READ_USER_NOTIFICATION:
      let newFilteredNotifications = state.allNewNotifications.filter((noti) => noti.id !== action.payload.id);
      let newAllReadNotifications = [action.payload, ...state.allReadNotifications];
      return { ...state, allNewNotifications: newFilteredNotifications, allReadNotifications: newAllReadNotifications, totalUnreadNotifications: 0 };
    case SET_USER_NOTIFICATION:
      return { ...state, notificationId: action.payload };
    case RESET_USER_NOTIFICATION:
      return { ...state, notificationId: null };

    default:
      return state;
  }
};

export default notificationsReducer;
